import Spinner from "src/components/common/Spinner";
import SubmitButton from "../common/SubmitButton";
import React from "react";
import sendRequest, { RequestError } from "../../network/SendRequest";
import { ENetworkEvent } from "../../network/enums/ENetworkEvent";
import SessionState from "../../SessionState";
import { EPage } from "./EPage";
import { getErrorMessageForCode } from "../../network/ErrorMessages";
import PageProps from "./PageProps";
import { GizmoResponse } from "../../GizmoResponse";

function WaitingPostAnswerPage({ socket, setPage, setError }: PageProps) {
  const clearError = () => {
    setError(undefined);
  };
  
  const changeAnswer = async () => {
    return;
  };
  
  const useGizmo = async () => {
    try {
      const response: GizmoResponse = await sendRequest(
        socket,
        ENetworkEvent.RequestGizmo,
        { roomCode: SessionState.roomCode },
        5000,
      );

      // TODO: We might not need this second round of GizmoResponseData depending on UX flow. Might just 
      // need to send canUseGizmo again.
      SessionState.canUseGizmo = response.can_use_gizmo;
      SessionState.players = response.players;
      
      if (response.can_use_gizmo) {
        setPage(EPage.Gizmo);
      } else {
        setPage(EPage.WaitingPostAnswer);
      }
      
      clearError();
    } catch (e) {
      const error = e as RequestError;
      setError(getErrorMessageForCode(error.error_code));
    }
  }

  return (
    <div className="mb-4">
      <Spinner />
      <br />
      <br />
      <div>
        <SubmitButton
          text={'Change Answer'}
          enabled={false}
          alignRight={false}
          onClick={changeAnswer}
        />
        &nbsp;&nbsp;
        <SubmitButton
          text={'Use Gizmo'}
          enabled={!!SessionState.canUseGizmo}
          alignRight={true}
          onClick={useGizmo}
        />
      </div>
    </div>
  );
}

export default WaitingPostAnswerPage;
