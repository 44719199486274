import Spinner from 'src/components/common/Spinner';

function ThankYouPage() {
  return (
    <div className="mb-4">
      <Spinner />
      <br />
      <div style={{ textAlign: 'center' }}>
        <p>
          We hope you had a fun time!
        </p>
      </div>
    </div>
  );
}

export default ThankYouPage;
