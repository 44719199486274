import React, { useState } from 'react';

type Props = {
  button: HTMLButtonElement | null;
  startsWith?: string;
};

function SubmittableInput({
  button,
  className,
  style,
  type,
  maxLength,
  placeholder,
  autoFocus,
  onChange,
  startsWith,
}: Props & React.InputHTMLAttributes<HTMLInputElement>) {
  let initialValue = '';
  if (startsWith) {
    initialValue = startsWith;
  }

  const [value, setValue] = useState<string>(initialValue);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      button?.click();
    }
  };

  const onChangeWithPatternCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (startsWith) {
      if (!event.target.value.startsWith(startsWith)) {
        if (event.target.value.length < startsWith.length) {
          event.target.value = startsWith;
        } else {
          event.target.value = `${startsWith}${event.target.value}`;
        }
      }
    }
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <input
      className={`${className} input is-large`}
      style={style}
      type={type}
      value={value}
      maxLength={maxLength}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={onChangeWithPatternCheck}
      onKeyDown={handleKeyDown}
    ></input>
  );
}

export default SubmittableInput;
