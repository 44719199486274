import React, { useState } from "react";
import PageProps from "src/components/pages/PageProps";
import SessionState, { Answer, Player } from 'src/SessionState';
import sendRequest, { RequestError } from "src/network/SendRequest";
import { ENetworkEvent } from "src/network/enums/ENetworkEvent";
import { EPage } from "src/components/pages/EPage";
import { getErrorMessageForCode } from "src/network/ErrorMessages";
import SubmitButton from "src/components/common/SubmitButton";
import SubmittableInput from "src/components/common/SubmittableInput";
import { EStage } from "../../enums/EStage";

enum EType {
  None,
  Player,
  Spectator,
}

function LoginPage({ socket, setPage, setError }: PageProps) {
  const [name, setName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [type, setType] = useState<EType>();
  const [chosenPlayer, setChosenPlayer] = useState<number>();
  const [didSelectOption, setDidSelectOption] = useState<boolean>(false);
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const roomCode = SessionState.roomCode;
  const hasPassword = SessionState.hasPassword;
  const allowSpectators = SessionState.allowSpectators;
  const players = SessionState.players;

  const clearError = () => {
    setError(undefined);
  };

  const sendLoginRoom = async () => {
    try {
      let response: {
        player_id: number;
        current_round: number;
        current_phase: number;
        current_stage: number;
        did_complete_stage: boolean;
        answer_start_requirements: string[];
        answers: Answer[];
        current_activity: number;
        activity_text: string;
        activity_options: string[];
        can_use_gizmo: boolean;
        players: Player[];
      };

      if (chosenPlayer) {
        response = await sendRequest(
          socket,
          ENetworkEvent.RejoinRoom,
          {
            roomCode,
            chosen_player: chosenPlayer,
            password: hasPassword ? password : undefined,
          },
          5000,
        );
      } else {
        response = await sendRequest(
          socket,
          ENetworkEvent.JoinRoom,
          {
            roomCode,
            name,
            password: hasPassword ? password : undefined,
            is_spectator: allowSpectators ? type === EType.Spectator : undefined,
          },
          5000,
        );
      }
      
      SessionState.playerId = response.player_id;
      SessionState.answers = response.answers;
      SessionState.canUseGizmo = response.can_use_gizmo;
      SessionState.players = response.players;
      
      if (SessionState.isPlayer()) {
        SessionState.answerStartWithRequirements = response.answer_start_requirements;
        // TODO: Remove the need for this initial value of empty string
        if (SessionState.answerStartWithRequirements.length === 0) {
          SessionState.answerStartWithRequirements.push("");
        }
        if (response.current_stage === EStage.Answer) {
          if (response.did_complete_stage) {
            setPage(EPage.WaitingPostAnswer);
          } else {
            setPage(EPage.Answer);
          }
        } else if (
          response.current_stage === EStage.Vote &&
          !response.did_complete_stage
        ) {
          setPage(EPage.Vote);
        } else {
          setPage(EPage.Waiting);
        }
      } else {
        SessionState.currentActivity = response.current_activity;
        SessionState.activityText = response.activity_text;
        SessionState.activityOptions = response.activity_options;
        
        if (
          response.current_stage === EStage.Prompt ||
          response.current_stage === EStage.Answer
        ) {
          if (response.current_activity > 0) {
            setPage(EPage.Activity);
          } else {
            setPage(EPage.SpectatorsWelcome);
          }
        } else if (
          response.current_stage === EStage.Reveal
        ) {
          if (response.current_phase > 0 ||
            response.current_round % 2 == 1) {
            setPage(EPage.Reaction);
          } else {
            setPage(EPage.Activity);
          }
        } else if (
          response.current_stage === EStage.Vote ||
          response.current_stage === EStage.Score
        ) {
          if (response.current_phase > 0 ||
              response.current_round % 2 == 1) {
            setPage(EPage.Reaction);
          } else {
            setPage(EPage.Vote);
          }
        } else {
          setPage(EPage.SpectatorsWelcome);
        }
      }

      clearError();
    } catch (e) {
      const error = e as RequestError;
      setError(getErrorMessageForCode(error.error_code));
    }
  };

  const isPlayer = () => {
    if (allowSpectators) {
      return type === EType.Player;
    } else {
      return true;
    }
  };

  const shouldShowName = () => {
    return getInactivePlayers()?.length === 0 || (!chosenPlayer && didSelectOption);
  };

  const shouldEnableButton = () => {
    if (allowSpectators && type === EType.None) {
      return false;
    }

    if (allowSpectators && type === EType.Spectator) {
      return true;
    }

    if (!chosenPlayer && (!name || !name.length)) {
      return false;
    }

    if (hasPassword) {
      if (!password || !password.length) {
        return false;
      }
    }

    return true;
  };

  const renderTypeChoice = () => {
    if (allowSpectators) {
      return (
        <div className="field">
          <label className="label">Type</label>
          <div className="control">
            <p>
              <label className="radio">
                <input
                  type="radio"
                  name="type"
                  onChange={() => {
                    setType(EType.Player);
                    setName(undefined);
                    setPassword(undefined);
                    setChosenPlayer(undefined);
                  }}
                />
                &nbsp;Player
              </label>
            </p>
            <p>
              <label className="radio">
                <input
                  type="radio"
                  name="type"
                  onChange={() => {
                    setType(EType.Spectator);
                    setName(undefined);
                    setPassword(undefined);
                    setChosenPlayer(undefined);
                  }}
                />
                &nbsp;Spectator
              </label>
            </p>
          </div>
        </div>
      );
    }
  };
  
  const getInactivePlayers = () => {
    return players?.filter(player => !player.is_active);
  }

  const renderPlayerChoice = () => {
    const inactivePlayers = getInactivePlayers();
    if (inactivePlayers?.length === 0) {
      return <div></div>;
    } else {
      return (
        <div className="field">
          <label className="label">Inactive Players</label>
          <div className="control">
            {inactivePlayers?.map((value) => (
              <p key={value.id}>
                <label className="radio">
                  <input
                    type="radio"
                    name="players"
                    onChange={() => {
                      setChosenPlayer(value.id);
                      setDidSelectOption(true);
                    }}
                  />
                  &nbsp;{value.name} ({value.score} Score)
                </label>
              </p>
            ))}
            <p>
              <label className="radio">
                <input
                  type="radio"
                  name="players"
                  onChange={() => {
                    setChosenPlayer(undefined);
                    setDidSelectOption(true);
                  }}
                />
                &nbsp;New Player
              </label>
            </p>
          </div>
        </div>
      );
    }
  };

  const renderName = () => {
    if (shouldShowName()) {
      return (
        <div className="field">
          <label className="label">Name</label>
          <p className="control has-icons-left">
            <SubmittableInput
              button={buttonRef.current}
              className="input is-large"
              type="text"
              placeholder="Name"
              autoFocus={true}
              onChange={(event) => setName(event.target.value)}
              maxLength={14}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-user"></i>
            </span>
          </p>
        </div>
      );
    }
  };

  const renderPlayer = () => {
    if (isPlayer())
      return (
        <div className="field">
          {renderPlayerChoice()}
          {renderName()}
        </div>
      );
  };

  const renderPassword = () => {
    if (hasPassword && isPlayer()) {
      return (
        <div className="field">
          <label className="label">Password</label>
          <p className="control has-icons-left">
            <SubmittableInput
              button={buttonRef.current}
              className="input is-large"
              type="password"
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-unlock"></i>
            </span>
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      {renderTypeChoice()}
      {renderPlayer()}
      {renderPassword()}
      <div>
        <SubmitButton
          ref={buttonRef}
          text={'Submit'}
          enabled={shouldEnableButton()}
          alignRight={false}
          onClick={sendLoginRoom}
        />
      </div>
    </div>
  );
}

export default LoginPage;
