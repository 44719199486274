import { Socket } from 'socket.io-client';
import { EErrorCode } from 'src/network/enums/EErrorCode';
import { ENetworkEvent } from 'src/network/enums/ENetworkEvent';

export type RequestError = {
  error_code: EErrorCode;
};

const sendRequest = <T>(
  socket: Socket | undefined,
  event: string,
  data: Record<string, unknown>,
  timeout: number,
): Promise<T> => {
  if (!socket) {
    return Promise.reject('sendRequest cannot send to an invalid socket.');
  }

  return new Promise<T>((resolve, reject) => {
    const responseEventName = `response.${event}`;
    const errorEventName = ENetworkEvent.Error;
    const responseCallback = (response: T) => {
      socket.off(responseEventName, responseCallback);
      resolve(response);
    };
    const errorCallback = (response: { code: EErrorCode }) => {
      socket.off(errorEventName, errorCallback);
      reject(response);
    };

    socket.on(responseEventName, responseCallback);
    socket.on(errorEventName, errorCallback);
    socket.emit(event, data);

    setTimeout(() => reject({ code: EErrorCode.Timeout }), timeout);
  });
};

export default sendRequest;
