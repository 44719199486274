import Spinner from 'src/components/common/Spinner';

function SpectatorsWelcomePage() {
  return (
    <div className="mb-4">
      <Spinner />
      <br />
      <div style={{ textAlign: 'center' }}>
        <p>
          Voting for your favorite answer adds a small amount of points to the
          players' scores.
        </p>
        <p>
          You answer "reactions" will be tabulated into special player awards at
          the end of the match.
        </p>
        <p>And there's other fun stuff for you to do along the way!</p>
      </div>
    </div>
  );
}

export default SpectatorsWelcomePage;
