export enum EActivity {
  None,
  PredictTheWinner,
  Superlatives,
  UseYourWordsTrivia,
  ClassicGameShowTrivia,
  TheSpectatorsGiveth,
  TheSpectatorsTaketh,
  SpectatorPoll,
}
