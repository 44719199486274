type Props = {
  error: string | undefined;
  setError: (error: string | undefined) => void;
};

function ErrorNotification({ error, setError }: Props) {
  const clearError = () => {
    setError(undefined);
  };

  return (
    <div>
      {error ? (
        <div className="notification is-danger mb-4">
          <button className="delete" onClick={clearError}></button>
          {error}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ErrorNotification;
