export enum ENetworkEvent {
  Connect = 'connect',
  Disconnect = 'disconnect',

  Error = 'server.error',
  RoomClosed = 'server.roomClosed',

  RequestRoom = 'web.requestRoom',
  JoinRoom = 'web.joinRoom',
  RejoinRoom = 'web.rejoinRoom',
  SendAnswer = 'web.sendAnswer',
  SendVote = 'web.sendVote',
  SendActivity = 'web.sendActivity',
  SendReaction = 'web.sendReaction',
  RequestGizmo = 'web.requestGizmo',
  SendGizmo = 'web.sendGizmo',

  RequestAnswer = 'client.requestAnswer',
  RequestVote = 'client.requestVote',
  RequestWaiting = 'client.requestWaiting',
}
