export enum EStage {
  Prompt,
  Answer,
  Reveal,
  Vote,
  Score,
  Finalize,
  Reset,
  End,
}
