import React, { useState } from 'react';
import PageProps from 'src/components/pages/PageProps';
import SubmitButton from 'src/components/common/SubmitButton';
import SubmittableInput from '../common/SubmittableInput';
import { EPage } from './EPage';
import { ENetworkEvent } from '../../network/enums/ENetworkEvent';
import sendRequest, { RequestError } from '../../network/SendRequest';
import { getErrorMessageForCode } from '../../network/ErrorMessages';
import SessionState from '../../SessionState';
import { GizmoResponse } from '../../GizmoResponse';

function AnswerPage({ socket, setPage, setError }: PageProps) {
  const initialAnswers = SessionState.answerStartWithRequirements ?? [''];
  const [answers, setAnswers] = useState<string[]>(initialAnswers);
  const submitRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const setAnswer = (index: number, value: string) => {
    if (index < answers.length) {
      const newAnswers = answers.slice();
      newAnswers[index] = value;
      setAnswers(newAnswers);
    }
  };

  const areValidAnswers = () => {
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i];
      const initialAnswer = initialAnswers[i];
      if (answer.length <= initialAnswer.length) {
        return false;
      }
    }

    return true;
  };

  const sendAnswer = async () => {
    if (areValidAnswers()) {
      try {
        const response: GizmoResponse = await sendRequest(
          socket,
          ENetworkEvent.SendAnswer,
          { roomCode: SessionState.roomCode, answers },
          5000,
        );

        SessionState.canUseGizmo = response.can_use_gizmo;
        SessionState.players = response.players;
       
        // We might already be in Waiting if the player was last to answer
        if (SessionState.currentPage == EPage.Answer) {
          setPage(EPage.WaitingPostAnswer);
        }
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const sendDecoy = async () => {
    try {
      await sendRequest(
        socket,
        ENetworkEvent.SendAnswer,
        { roomCode: SessionState.roomCode, answers: null },
        5000,
      );
      setPage(EPage.WaitingPostAnswer);
      clearError();
    } catch (e) {
      const error = e as RequestError;
      setError(getErrorMessageForCode(error.error_code));
    }
  };

  const renderInputs = () => {
    return (
      <div className="field">
        <label className="label">Answer</label>
        {SessionState.answerStartWithRequirements?.map((value, index) => (
          <p key={index} className="control has-icons-left">
            <SubmittableInput
              button={null}
              type="text"
              placeholder="Answer"
              autoFocus={true}
              onChange={(event) => setAnswer(index, event.target.value)}
              startsWith={value}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-gamepad"></i>
            </span>
          </p>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderInputs()}
      <div>
        <SubmitButton
          text={'Decoy'}
          enabled={true}
          alignRight={false}
          onClick={sendDecoy}
        />
        &nbsp;&nbsp;
        <SubmitButton
          ref={submitRef}
          text={'Submit'}
          enabled={areValidAnswers()}
          alignRight={true}
          onClick={sendAnswer}
        />
      </div>
    </div>
  );
}

export default AnswerPage;
