import React, { useState } from "react";
import PageProps from "src/components/pages/PageProps";
import SubmitButton from "src/components/common/SubmitButton";
import { EPage } from "./EPage";
import { ENetworkEvent } from "../../network/enums/ENetworkEvent";
import sendRequest, { RequestError } from "../../network/SendRequest";
import { getErrorMessageForCode } from "../../network/ErrorMessages";
import SessionState, { Player } from "../../SessionState";
import { EGizmo } from "../../enums/EGizmo";

function GizmoPage({ socket, setPage, setError }: PageProps) {
  const [gizmo, setGizmo] = useState<number>();
  const [target, setTarget] = useState<number>();
  const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const clearError = () => {
    setError(undefined);
  };

  const isValidGizmo = () => {
    if (!!gizmo && gizmo > 0) {
      if (gizmo === EGizmo.ShareTheWealth) {
        return !!target && target > 0;
      } else {
        return true;
      }
    }
    return false;
  };
  
  const cancel = async () => {
    setPage(EPage.WaitingPostAnswer);
  };

  const sendGizmo = async () => {
    if (isValidGizmo()) {
      try {
        const response: {
          isEnding: boolean;
        }  = await sendRequest(
          socket,
          ENetworkEvent.SendGizmo,
          { roomCode: SessionState.roomCode, gizmo, target },
          5000
        );
        
        SessionState.canUseGizmo = false;
        setPage(EPage.WaitingPostAnswer);
        
        clearError();
      } catch (e) {
        const error = e as RequestError;
        setError(getErrorMessageForCode(error.error_code));
      }
    }
  };

  const renderGizmos = () => {
    return (
      <div className="field">
        <label className="label">Choose a Gizmo</label>
        <div className="control">
          <p key={EGizmo.ShareTheWealth}>
            <label className="radio">
              <input
                type="radio"
                name="gizmos"
                onChange={() => {
                  setGizmo(EGizmo.ShareTheWealth);
                  setTarget(0);
                }}
              />
              &nbsp;Use Their Words
            </label>
          </p>
          <p key={EGizmo.DoubleDown}>
            <label className="radio">
              <input
                type="radio"
                name="gizmos"
                onChange={() => {
                  setGizmo(EGizmo.DoubleDown);
                  setTarget(0);
                }}
              />
              &nbsp;Double Down
            </label>
          </p>
          <p key={EGizmo.OnTheHouse}>
            <label className="radio">
              <input
                type="radio"
                name="gizmos"
                onChange={() => {
                  setGizmo(EGizmo.OnTheHouse);
                  setTarget(undefined);
                }}
              />
              &nbsp;On The House
            </label>
          </p>
        </div>
      </div>
    );
  };
  
  const renderTargets = () => {
    let showTargets = false;
    if (gizmo === EGizmo.ShareTheWealth) {
      showTargets = true;
    }
    
    if (showTargets) {
      return (
        <div>
          <label className="label">Choose a Target</label>
          <div className="control">
            {
              SessionState.players?.filter(player => player.id != SessionState.playerId)
                .map((player, index) =>
              {
                return (
                  <p key={index}>
                    <label className="radio">
                      <input
                        type="radio"
                        name="answers"
                        onChange={() => {
                          setTarget(player.id);
                        }}
                        checked={target === player.id}
                      />
                      &nbsp;{player.name}
                    </label>
                  </p>
                );
              })
            }
          </div>
        </div>
      );
    } else {
      return (<div></div>);
    }
  };

  return (
    <div>
      <div className="field">{renderGizmos()}</div>
      <div className="field">{renderTargets()}</div>
      <div>
        <SubmitButton
          text={'Cancel'}
          enabled={true}
          alignRight={false}
          onClick={cancel}
        />
        &nbsp;&nbsp;
        <SubmitButton
          ref={buttonRef}
          text={"Submit"}
          enabled={isValidGizmo()}
          alignRight={true}
          onClick={sendGizmo}
        />
      </div>
    </div>
  );
}

export default GizmoPage;
