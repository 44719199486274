export enum EPage {
  Enter,
  Login,
  Waiting,
  Answer,
  Vote,
  SpectatorsWelcome,
  Activity,
  Reaction,
  ThankYou,
  WaitingPostAnswer,
  Gizmo
}
