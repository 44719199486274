import React, { ForwardedRef, useState } from 'react';

type Props = {
  text: string;
  enabled: boolean;
  alignRight: boolean;
  onClick: () => Promise<void>;
};

const SubmitButton = React.forwardRef(
  (
    { text, enabled, alignRight, onClick }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const [showSpinner, setShowSpinner] = useState<boolean>();

    const handleClick = async () => {
      setShowSpinner(true);
      await onClick();
      setShowSpinner(false);
    };

    return (
      <button
        className={`button is-link is-large ${showSpinner ? 'is-loading' : ''}`}
        ref={ref}
        disabled={showSpinner || !enabled}
        onClick={handleClick}
        style={{ float: alignRight ? 'right' : 'none' }}
      >
        {text}
      </button>
    );
  },
);

export default SubmitButton;
