import Spinner from 'src/components/common/Spinner';

function WaitingPage() {
  return (
    <div className="mb-4">
      <Spinner />
    </div>
  );
}

export default WaitingPage;
